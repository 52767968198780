* {
  padding: 0%;
  margin: 0;
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  display: none;
}

input:required:invalid {
  border-color: #c00000;
}


/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  /* background-color: #282c34; */
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgba(0, 0, 0, 0.7);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}